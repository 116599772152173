<template>
    <div>
        <van-nav-bar title="巡检整改" left-text="返回" left-arrow @click-left="$router.go(-1)" />
        <div>
            <van-cell-group title="整改信息">
                <van-cell title="检查类型" :value="model.TYPE_NAME" />
                <van-cell title="检查位置" :value="model.LOCATION" />
                <van-cell title="存在问题" :value="model.PROBLEM" />
                <!-- <van-field v-model="model.NAME" type="text" label="荣誉名称" placeholder="请输入荣誉名称" />
                <van-field label="荣获日期" :value="model.OBTAIN_DT | datetime('YYYY-MM-DD')" @click="time.show = true"
                    placeholder="请选择荣获日期" is-link />
                <van-popup v-model="time.show" round position="bottom">
                    <van-datetime-picker type="date" :min-date="time.minDate" v-model="time.curDate"
                        @cancel="time.show = false" @confirm="timeConfirm" />
                </van-popup> -->
                <van-grid direction="horizontal" :column-num="2" v-for="(item, index) in model.ITEM_LIST" :key="index">
                    <van-grid-item>
                        <span>{{item.NAME}}</span>
                    </van-grid-item>
                    <!-- <van-grid-item :text="item.NAME" /> -->

                    <van-grid-item>
                        <van-radio-group v-model="item.STATUS" direction="horizontal" disabled>
                            <van-radio :name="0">合格</van-radio>
                            <van-radio :name="1">整改</van-radio>
                        </van-radio-group>
                    </van-grid-item>
                </van-grid>
            </van-cell-group>
            <!-- <van-cell-group title="整改信息">
                <van-grid direction="horizontal" :column-num="2">
                    <van-grid-item icon="photo-o" text="文字" />
                    <van-grid-item icon="photo-o" text="文字" />
                </van-grid>
            </van-cell-group> -->
            <van-cell-group title="整改说明">
                <van-field v-model="model.RECTIFY_DESC" autosize type="textarea" maxlength="100" placeholder="请输入整改说明"
                    show-word-limit />
            </van-cell-group>

            <van-cell-group title="整改图片">
                <van-uploader :after-read="afterRead" multiple v-model="picList" @delete="deleteImg" max-count="9"
                    style="margin: 10px 0px 0px 10px" />
            </van-cell-group>

            <van-cell-group title="签名">
                <sign-canvas v-if="!model.SIGN_KV" class="sign-canvas" ref="SignCanvas" :options="options" />
                <img class="sign-image" v-if="model.SIGN_KV" :src="model.SIGN_KV.Value" alt="">
                <div class="sign-btns">
                    <van-button type="danger" size="small" @click="canvasClear()"
                        style="margin-right: 20px;">清空</van-button>
                    <van-button type="primary" size="small" @click="saveAsImg()">保存</van-button>
                </div>
            </van-cell-group>

            <div style="margin: 15px">
                <van-button type="primary" size="large" icon="success" block @click="submit" :loading="ret.loading"
                    loading-text="正在提交。。。">确认编辑</van-button>
                <br />
                <van-button plain type="default" size="large" icon="cross" block
                    @click="$router.go(-1)">取消返回</van-button>
            </div>
        </div>
    </div>
</template>

<script>
    import moment from "moment";
    import { Toast } from "vant";

    export default {
        data() {
            return {
                id: this.$route.query.id,
                model: {},
                images: [],
                picList: [],
                ret: {
                    loading: false
                },
                time: {
                    show: false,
                    minDate: moment().add(-1, 'y').toDate(),
                    curDate: moment().toDate(),
                },
                sign: "",
                options: {
                    lastWriteSpeed: 1, //书写速度 [Number] 可选
                    lastWriteWidth: 2, //下笔的宽度 [Number] 可选
                    lineCap: "round", //线条的边缘类型 [butt]平直的边缘 [round]圆形线帽 [square]    正方形线帽
                    lineJoin: "round", //线条交汇时边角的类型  [bevel]创建斜角 [round]创建圆角 [miter]创建尖角。
                    canvasWidth: 300, //canvas宽高 [Number] 可选
                    canvasHeight: 120, //高度  [Number] 可选
                    isShowBorder: true, //是否显示边框 [可选]
                    bgColor: "#fff", //背景色 [String] 可选
                    borderWidth: 1, // 网格线宽度  [Number] 可选
                    borderColor: "#ccc", //网格颜色  [String] 可选
                    writeWidth: 5, //基础轨迹宽度  [Number] 可选
                    maxWriteWidth: 30, // 写字模式最大线宽  [Number] 可选
                    minWriteWidth: 5, // 写字模式最小线宽  [Number] 可选
                    writeColor: "#101010", // 轨迹颜色  [String] 可选
                    isSign: true, //签名模式 [Boolean] 默认为非签名模式,有线框, 当设置为true的时候没有任何线框
                    imgType: "png" //下载的图片格式  [String] 可选为 jpeg  canvas本是透明背景的
                },
            }
        },
        created() {
            this.getHonor();
        },

        methods: {
            getHonor() {
                let self = this;
                this.whale.remote.getResult({
                    url: "/api/Mobile/INS/RecordApi/GetModel",
                    data: { id: this.id },
                    completed: function (res) {
                        self.model = res.DATA
                        self.picList = res.DATA.RECTIFY_IMAGE_KVS.map(x => { return { url: x.Value } })
                        self.images = res.DATA.RECTIFY_IMAGE_KVS.map(x => x.Key)

                        self.getSign();
                    },
                });
            },
            afterRead(files) {
                let self = this
                if (!Array.isArray(files)) {
                    files = [files]
                }
                files.forEach(item => {
                    let b64Arr = item.content.split(',')
                    this.whale.remote.getResult({
                        url: "/api/Mobile/ToolsApi/UploadImage",
                        data: { prefix: "ins", base64: b64Arr[1] },
                        completed(its) {
                            self.model.RECTIFY_IMAGE_KVS.push({ Key: its.DATA })
                        },
                    })
                })
                setTimeout({}, 100)
            },
            deleteImg(files, { index }) {
                this.images.splice(index, 1)
            },
            submit() {
                let self = this;
                // self.model.IMAGES = self.images.join(',')

                if (!self.model.RECTIFY_DESC) {
                    Toast.fail("请输入整改说明")
                    return;
                }
                if (self.model.RECTIFY_IMAGE_KVS.length == 0) {
                    Toast.fail("请上传整改图片")
                    return;
                }
                if (self.model.SIGN_KV == null) {
                    Toast.fail("请签名")
                    return;
                }

                self.ret.loading = true;
                this.whale.remote.getResult({
                    url: "/api/Mobile/INS/RecordApi/Rectify",
                    data: this.model,
                    finally() {
                        self.ret.loading = false;
                    },
                    completed() {
                        self.$dialog.alert({
                            message: "更改成功",
                            confirmButtonText: "确定",
                        }).then(() => {
                            self.$router.go(-1);

                        })
                    }
                })
            },
            canvasClear() {
                if (this.model.SIGN_KV) {
                    this.model.SIGN_KV = null
                } else {
                    this.$refs.SignCanvas.canvasClear();
                }

            },
            // 保存图片
            saveAsImg() {
                const img = this.$refs.SignCanvas.saveAsImg();
                if (img) {
                    const parts = img.split(";base64,");
                    const contentType = parts[0].split(":")[1];
                    const raw = window.atob(parts[1]);
                    const rawLength = raw.length;
                    const uInt8Array = new Uint8Array(rawLength);
                    // 将 Base64 转换为 Uint8Array
                    for (let i = 0; i < rawLength; ++i) {
                        uInt8Array[i] = raw.charCodeAt(i);
                    }
                    // 创建 Blob 对象
                    let blob = new Blob([uInt8Array], { type: contentType });
                    this.H5SignUploadPost(blob);
                } else {
                    this.$message.warning('没有签名')
                }
            },
            H5SignUploadPost(blob) {
                const formData = new FormData();
                formData.append("file", blob, "sign.png");
                let self = this;
                this.whale.remote.uploadSign({
                    url: 'api/Mobile/ToolsApi/UpLoadSign',
                    data: formData,
                    completed: function (resp) {
                        self.model.SIGN_KV = {
                            Key: resp.Data.DATA.NAME,
                            Value: resp.Data.DATA.OSS_URL
                        }
                    }
                })
            },
            getSign() {
                let self = this
                self.whale.remote.getResult({
                    url: 'api/Mobile/ToolsApi/GetSign',
                    completed(res) {
                        self.model.SIGN_KV = {
                            Key: res.NAME,
                            Value: res.OSS_URL
                        }
                    }
                })
            }
        }
    }
</script>
<style scoped>
    .sign-canvas {
        margin-top: 10px;
        display: block;
        margin: 0 auto;
    }

    .sign-image {
        margin-top: 10px;
        display: block;
        margin: 0 auto;
        width: 300px;
        height: 120px;
    }

    .sign-btns {
        margin-top: 10px;
        text-align: center;
        margin-bottom: 10px;
    }
</style>